import React from "react"
import { Helmet } from "react-helmet"
import Seo from "../seo"
import MainCards from "./main-cards"
const HowItWorks = () => {
  return (
    <div>
      <Seo
        title="How IBM Maximo application works on the inside "
        description="Discover the process of building your IBM maximo and take your application to the next level"
      />
      <Helmet prepend>
        <meta
          property="og:title"
          content="How it Works.The MAXapps Way of Building"
        />
        <meta
          property="og:description"
          content="With MAXapps, building Maximo mobile applications becomes easy. Uniting the IBM Maximo workflow and MAXapps Designer to design, stage, and deploy, you can take..."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/how-it-works.jpg"
        />
        <meta
          property="og:url"
          content="https://maxapps-maximo.com/how-it-works"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta
          name="twitter:title"
          content="How it Works.The MAXapps Way of Building"
        />
        <meta
          name="twitter:description"
          content="With MAXapps, building Maximo mobile applications becomes easy. Uniting the IBM Maximo workflow and MAXapps Designer to design, stage, and deploy, you can take..."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/how-it-works.jpg"
        />
      </Helmet>
      <MainCards />
    </div>
  )
}

export default HowItWorks
