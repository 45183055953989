import React from "react"
import CardItem from "./card-item"

import { useStaticQuery, graphql } from "gatsby"

const HowCards = ({ _card }) => {
  let cardInfo = []
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(how-cards)/" }) {
          exports {
            HowItWorks {
              toPage
              subTitle
              title
              cardImage
              titleBtn
            }
          }
        }
      }
    `)
    cardInfo = data.mdx.exports.HowItWorks
  } catch (error) {
    cardInfo = _card
  }

  return (
    <div
      id="howCards"
      className="row py-2 mt-5 px-4 px-md-5"
      data-testid="howCards"
    >
      {cardInfo?.map((item, index) => {
        return (
          <div className="col-12 col-lg-6 p-0" key={index} data-testid={index}>
            <CardItem {...item} />
          </div>
        )
      })}
    </div>
  )
}

export default HowCards
