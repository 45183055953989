import React from "react"
import HowItWorks from "../../components/how-it-works"
import Layout from "../../components/layout/t1/layout"

const HowItWorksPage = () => {
  return (
    <Layout>
      <HowItWorks />
    </Layout>
  )
}

export default HowItWorksPage
