import React from "react"
import HowCards from "../how-cards"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import YellowButton from "../../home/buttons/yellow-button"
import { BsArrowRight } from "react-icons/bs"

const MainCards = ({ _mockPage }) => {
  let info = []
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(main-cards)/" }) {
          frontmatter {
            titleBottomHowitworks1
            titleBottomHowitworks2
            titleButton
            sizeButton
            titleMain
            subTitleMain
          }
        }
      }
    `)
    info = data.mdx.frontmatter
  } catch (error) {
    info = _mockPage
  }
  return (
    <div id="MainCards" className=" MainCards py-5" data-testid="MainCards">
      <div className="nav py-6">
        <div className="container">
          <h1 className="title">
            {info.titleMain} <br />
            <span className="subTitleMain"> {info.subTitleMain}</span>
          </h1>
          <p>
            With MAXapps, building Maximo mobile applications becomes easy.
            Uniting the IBM Maximo workflow and MAXapps Designer to design,
            stage, and deploy, you can take your Maximo to the next level.
          </p>
        </div>
      </div>

      <HowCards {...info} />
      <div className="row justify-content-center my-5 py-5 ">
        <span className="centeredText ">
          {info.titleBottomHowitworks1} <br />
          {info.titleBottomHowitworks2}
        </span>
        <div className="btn-mainhow-bottom mt-3 py-2" data-testid="Button">
          <YellowButton
            data={info.titleButton}
            size={info.sizeButton}
            children={
              <BsArrowRight size={20} className="yellow-btn-arrow ms-2" />
            }
            link="/get-started/"
          />
        </div>
      </div>
    </div>
  )
}

export default MainCards
